import "../../resources/less/Family/LoggedOut/About.less";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";
import JQuery from "jquery";

class FamilyAbout {
  private worldExamplesCarousel: Glide;
  private slideTitle: JQuery<HTMLElement>;
  private slideTitleCursor: JQuery<HTMLElement>;
  private carouselNavButtons: JQuery<HTMLElement>;

  constructor() {
    this.slideTitle = $("#slide-title");
    this.slideTitleCursor = $("#cursor");
    this.carouselNavButtons = $(".glide__arrow");
    this.initWorldExamplesCarousel();
  }

  /**
   * Init the world examples slide with desired options
   * For more details visit https://glidejs.com/docs/api/
   * @private
   */
  private initWorldExamplesCarousel(): void {
    this.worldExamplesCarousel = new Glide("#world-examples-carousel", {
      type: "carousel",
      startAt: 0,
      perView: 1,
      rewind: false,
      focusAt: 0,
      bound: true,
    });
    this.worldExamplesCarousel.on(["run"], () => {
      this.slideTitle.find("h3").remove();
    });
    this.worldExamplesCarousel.on(["mount.after", "run.after"], () => {
      const slideTitleTxt = $(".glide__slide--active").attr("data-slide-title");
      const index = 0;
      this.slideTitle.prepend("<h3></h3>");
      this.slideTitleCursor.removeClass("idle");
      this.typingAnimation(index, this.slideTitle.find("h3"), slideTitleTxt);
    });
    this.worldExamplesCarousel.mount();
  }

  private typingAnimation(
    index: number,
    title: JQuery<HTMLElement>,
    text: string,
  ): void {
    const typingTimeout = setTimeout(() => {
      index++;
      this.updateText(index, title, text);
      if (index < text.length) {
        this.typingAnimation(index, title, text);
      }
      clearTimeout(typingTimeout);
    }, 50);
  }

  private updateText(
    index: number,
    title: JQuery<HTMLElement>,
    text: string,
  ): void {
    const typing = title;
    const lines = text.substring(0, index);

    if (typing == null) {
      return;
    }

    // Update text
    typing.text(lines);

    // If typing is complete, show idle cursor for 1 second
    if (index === text.length) {
      this.slideTitleCursor.addClass("idle");
    }
  }
}

$(window).on("load", () => {
  new FamilyAbout();
});
